import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import i18n from "../i18n";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
  users: [],
  isModalShown: false,
  hasMore: true,
  totalMatches: 0,
  status: "idle",
};

export const searchUser = createAsyncThunk(
  "find-user/users",
  async (filter, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await fetch(`${serverURL}/search-users/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filter,
        skip: state.searchUser.users.length,
        limit: 10,
        language: i18n.language,
      }),
    });
    if (!response.ok) throw new Error(await response.text());
    const result = await response.json();
    return result;
  }
);

export const searchUserSlice = createSlice({
  name: "searchUser",
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.isModalShown = !state.isModalShown;
    },
    resetUsers: (state) => {
      state.users = [];
      state.hasMore = true;
      state.totalMatches = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(searchUser.fulfilled, (state, action) => {
      state.totalMatches = action.payload.totalMatches;
      state.hasMore = action.payload.hasMore;
      state.users = [...state.users, ...action.payload.users];
      if (state.users.length >= state.totalMatches) {
        state.hasMore = false;
      }
      state.status = "success";
    });
    builder.addCase(searchUser.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export default searchUserSlice.reducer;
export const { toggleModal, resetUsers } = searchUserSlice.actions;
