import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LocationRange from "../components/map/LocationRange";
import UsersResultModal from "../components/search-user/UsersResultModal";
import { resetUsers, searchUser, toggleModal } from "../redux/searchUserSlice";
import Utils from "../utils/Utils";
const { pathToFormattedDirectory, pathSeparator } = Utils;

export default function SearchUser() {
  const { profile } = useSelector((state) => state.auth);
  const { isModalShown } = useSelector((state) => state.searchUser);
  const dispatch = useDispatch();
  const indexOfAll = profile.subscribedInterests.findIndex(
    (item) => item.path === pathSeparator + "All"
  );
  const [location, setLocation] = useState(profile.defaultLocation);
  const [interestRange, setInterestRange] = useState(() => {
    const saved = localStorage.getItem("interestRange");
    return saved !== null ? JSON.parse(saved) : profile.interestRange;
  });
  const [path, setPath] = useState(pathSeparator + "All");
  const [isDownLine, setIsDownLine] = useState(() => {
    const saved = localStorage.getItem("isDownLine");
    return saved !== null ? JSON.parse(saved) : true;
  });

  const [isUpLine, setIsUpLine] = useState(() => {
    const saved = localStorage.getItem("isUpLine");
    return saved !== null ? JSON.parse(saved) : true;
  });

  const handleUpLineChange = (e) => {
    setIsUpLine(e.target.checked);
    localStorage.setItem("isUpLine", JSON.stringify(e.target.checked));
  };

  const handleDownLineChange = (e) => {
    setIsDownLine(e.target.checked);
    localStorage.setItem("isDownLine", JSON.stringify(e.target.checked));
  };

  const handleInterestRangeChange = (value) => {
    setInterestRange(value);
    localStorage.setItem("interestRange", JSON.stringify(value));
  };

  const { t } = useTranslation(["user", "common"]);
  const filter = { location, interestRange, path, isUpLine, isDownLine };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetUsers());
    dispatch(searchUser(filter));
    dispatch(toggleModal());
  };

  return (
    <Box className="w-full" alignSelf="center">
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          padding: "1rem",
          my: 1,
          borderRadius: 3,
        }}
      >
        <h2 className="text-3xl text-center my-3 mb-6">{t("searchUser")}</h2>
        <form onSubmit={handleSubmit}>
          <FormControl
            sx={{
              minWidth: 120,
              mb: 1,
              width: "100%",
            }}
          >
            <InputLabel id="path-label">{t("selectDirectory")}</InputLabel>
            <Select
              labelId="path-label"
              id="path"
              label={t("selectDirectory")}
              value={path}
              onChange={(e) => setPath(e.target.value)}
            >
              {indexOfAll === -1 && (
                <MenuItem
                  key={pathSeparator + "All"}
                  value={pathSeparator + "All"}
                >
                  {pathToFormattedDirectory(pathSeparator + "All")}
                </MenuItem>
              )}
              {profile.subscribedInterests.map((item) => (
                <MenuItem key={item.path} value={item.path}>
                  {pathToFormattedDirectory(item.path)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormGroup
            sx={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              control={
                <Switch checked={isUpLine} onChange={handleUpLineChange} />
              }
              label={t("includeAllUpLine")}
            />
            <FormControlLabel
              control={
                <Switch checked={isDownLine} onChange={handleDownLineChange} />
              }
              label={t("includeAllDownLine")}
            />
          </FormGroup>
          <LocationRange
            location={location}
            setLocation={setLocation}
            interestRange={interestRange}
            setInterestRange={handleInterestRangeChange}
          />
          <Box className="flex justify-center">
            <Button
              type="submit"
              color="success"
              variant="contained"
              sx={{ borderRadius: 10, mt: 2, mb: 2 }}
            >
              {t("searchUser")}
            </Button>
          </Box>
        </form>
        {isModalShown && <UsersResultModal filter={filter} />}
      </Paper>
    </Box>
  );
}
