import { Container } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Cancel from "../pages/Cancel";
import ChangeEmail from "../pages/ChangeEmail";
import Messages from "../pages/chat/Messages";
import Directory from "../pages/Directory";
import Events from "../pages/Events";
import Feeds from "../pages/Feeds";
import Help from "../pages/Help";
import Login from "../pages/Login";
import PasswordResetSuccess from "../pages/PasswordResetSuccess";
import Plans from "../pages/plan/Plans";
import Privacy from "../pages/Privacy";
import Profile from "../pages/Profile";
import PublicFeeds from "../pages/PublicFeeds";
import Register from "../pages/Register";
import ResetPassword from "../pages/ResetPassword";
import ResetPasswordRequest from "../pages/ResetPasswordRequest";
import SearchUser from "../pages/SearchUser";
import SignUpSuccess from "../pages/SignUpSuccess";
import Terms from "../pages/Terms";
import VerifyEmail from "../pages/VerifyEmail";
import AuthOutlet from "./AuthOutlet";
import PrivateOutlet from "./PrivateOutlet";

function Contents() {
  return (
    <Container
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      <Routes>
        <Route element={<AuthOutlet />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>
        <Route path="/:path" element={<PublicFeeds />} />
        <Route path="/:parent/:path" element={<PublicFeeds />} />
        <Route index element={<Directory />} />
        <Route path="/signup-success/" element={<SignUpSuccess />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route
          path="/reset-password-request"
          element={<ResetPasswordRequest />}
        />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route
          path="/password-reset-success/"
          element={<PasswordResetSuccess />}
        />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />

        <Route element={<PrivateOutlet />}>
          <Route path="/feeds" element={<Feeds />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/events" element={<Events />} />
          <Route path="/help" element={<Help />} />
          <Route path="/change-email" element={<ChangeEmail />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/search-user" element={<SearchUser />} />
        </Route>
      </Routes>
    </Container>
  );
}

export default React.memo(Contents);
