import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slider,
} from "@mui/material";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import RangeCircle from "./RangeCircle";

const maxRange = 100;
const marksCount = 10;
const defaultZoom = 10;
let map;
const marks = [{ value: 0, label: "0" }];
for (let i = maxRange; i > 0; i -= Math.ceil(maxRange / marksCount))
  marks.push({ value: i, label: i.toString() });

const getBrowserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve({ lat: pos.coords.latitude, lng: pos.coords.longitude });
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export default function LocationRange({
  location,
  defaultLocation,
  setLocation,
  interestRange,
  setInterestRange,
  ipLocation,
}) {
  const { t } = useTranslation(["common"]);
  const [zoom, setZoom] = useState(defaultZoom);

  const focusToCircle = useCallback(() => {
    setZoom(defaultZoom);
    if (map) map.panTo(location);
  }, [location]);
  const focusToCircleRef = useRef(focusToCircle);
  focusToCircleRef.current = focusToCircle;

  // add buttons to the map
  const handleMapLoad = (m) => {
    map = m;
    map.addListener("zoom_changed", () => {
      setZoom(map.getZoom());
    });
    // button to get location
    const locateButton = document.createElement("button");
    const locateIcon = document.createElement("img");
    locateButton.type = "button";
    locateIcon.src =
      "https://img.icons8.com/color-glass/48/google-maps-new.png";
    locateIcon.alt = t("locate");
    locateButton.appendChild(locateIcon);
    locateButton.title = t("addCurrentLocation");
    locateButton.classList.add("locate-button");
    locateButton.addEventListener("click", () => {
      getBrowserLocation()
        .then((coords) => {
          setLocation({ ...coords, setByUser: true });
          if (map) map.panTo(coords);
        })
        .catch((error) => {
          console.error("Error getting location.", error);
        });
    });
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      locateButton
    );
    // button to focus on the circle
    const toCircle = document.createElement("button");
    const circleIcon = document.createElement("img");
    toCircle.type = "button";
    circleIcon.src = "https://img.icons8.com/color/48/define-location--v1.png";
    circleIcon.alt = t("circle");
    toCircle.appendChild(circleIcon);
    toCircle.title = t("focusToCircle");
    toCircle.classList.add("circle-button");
    toCircle.addEventListener("click", () => focusToCircleRef.current());
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      toCircle
    );
  };
  return (
    <Box my={1}>
      <FormControl
        variant="outlined"
        sx={{ width: "100%" }}
        InputLabelProps={{ shrink: !!interestRange }}
      >
        <InputLabel htmlFor="interest-range">{t("interestRange")}</InputLabel>
        <OutlinedInput
          id="interest-range"
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            "aria-label": "interest-range",
          }}
          label={t("interestRange")}
          value={interestRange}
          onChange={(e) => setInterestRange(e.target.value)}
          endAdornment={<InputAdornment position="end">km</InputAdornment>}
          aria-describedby="interest-range"
        />
      </FormControl>
      <Box>
        <Slider
          aria-label="Small steps"
          value={interestRange}
          onChange={(event) => setInterestRange(event.target.value)}
          step={1}
          marks={marks}
          min={0}
          sx={{
            mt: 0.5,
          }}
          max={maxRange}
          valueLabelDisplay="auto"
        />
      </Box>
      {!isNaN(location?.lat) && !isNaN(location?.lng) ? (
        <GoogleMap
          onLoad={handleMapLoad}
          mapContainerClassName="map-container"
          center={location}
          zoom={zoom}
        >
          {location?.lat && location?.lng && (
            <>
              <RangeCircle
                interestRange={interestRange}
                setInterestRange={setInterestRange}
                location={location}
                setLocation={setLocation}
              />
              {location?.lat && (
                <MarkerF
                  position={location}
                  icon={{
                    url: "location-pin.png",
                    scaledSize: new window.google.maps.Size(35, 35),
                  }}
                />
              )}
            </>
          )}
        </GoogleMap>
      ) : (
        <Loader />
      )}
    </Box>
  );
}
