import { CircleF } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";

function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

function RangeCircle({
  interestRange,
  setInterestRange,
  location,
  setLocation,
}) {
  const [center, setCenter] = useState({ ...location });

  useEffect(() => {
    setCenter((prevCenter) => {
      if (location.lat !== prevCenter.lat || location.lng !== prevCenter.lng) {
        return { ...location };
      }
      return prevCenter;
    });
  }, [location]);

  // debounce the center change
  const debouncedSetLocation = useCallback(debounce(setLocation, 300), [
    setLocation,
  ]);

  const handleCenterChange = () => {
    setLocation({ ...center, setByUser: true });
  };

  const handleRadiusChange = useCallback(
    (r) => {
      if (r) {
        setInterestRange(Math.floor(r / 1000));
      }
    },
    [setInterestRange]
  );

  return (
    <CircleF
      center={center}
      radius={interestRange * 1000}
      options={{
        strokeColor: "#339af0",
        fillColor: "#339af0",
        fillOpacity: 0.25,
        zIndex: 1,
      }}
      draggable
      editable
      onLoad={(circle) => {
        circle.addListener("radius_changed", () => {
          const r = circle.getRadius();
          handleRadiusChange(r);
        });
        circle.addListener("center_changed", () => {
          const c = circle.getCenter();
          const coords = { lat: c.lat(), lng: c.lng() };
          setCenter((prevCenter) => {
            if (
              coords.lat !== prevCenter.lat ||
              coords.lng !== prevCenter.lng
            ) {
              debouncedSetLocation({ ...coords, setByUser: true });
              return coords;
            }
            return prevCenter;
          });
        });
      }}
      onDragEnd={handleCenterChange}
    />
  );
}

export default RangeCircle;
