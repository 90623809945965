import { Box, Slider, TextField } from "@mui/material";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import RangeCircle from "./RangeCircle";

const maxRange = 100;
const marksCount = 10;
const defaultZoom = 10;
let map;
const marks = [{ value: 0, label: "0" }];
for (let i = maxRange; i > 0; i -= Math.ceil(maxRange / marksCount))
  marks.push({ value: i, label: i.toString() });

function LocationAndRange({
  location,
  setLocation,
  interestRange,
  setInterestRange,
  ipLocation,
}) {
  console.log(ipLocation);

  const { t } = useTranslation(["common"]);
  const [zoom, setZoom] = useState(defaultZoom);
  const [center, setCenter] = useState({ ...location });
  const [userLocation, setUserLocation] = useState(null);

  const updateDefaultLocation = useCallback(() => {
    if (!center.lat && !center.lng) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const coords = {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            };
            if (map) map.panTo(coords);
            setUserLocation(coords);
            setCenter(coords);
            setLocation(coords);
          },
          (error) => {
            console.error("Error getting location.", error);
            if (!location.lat && !location.lng) {
              const coords = {
                lat: 48.8584,
                lng: 2.2945,
              };
              setCenter(coords);
              setLocation(coords);
              if (map) map.panTo(coords);
            }
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  }, [setCenter, setLocation, location, center.lat, center.lng]);

  const focusToCircle = useCallback(() => {
    setZoom(defaultZoom);
    if (map) map.panTo(location);
  }, [location]);

  // set location if not set in database
  const hasRunRef = useRef(false);
  useEffect(() => {
    if (hasRunRef.current) return;
    hasRunRef.current = true;
    if (!center?.lat && !center?.lng) {
      updateDefaultLocation();
    }
  }, [updateDefaultLocation, center]);

  const focusToCircleRef = useRef(focusToCircle);
  focusToCircleRef.current = focusToCircle;

  // add buttons to the map
  const handleMapLoad = (m) => {
    map = m;
    map.addListener("zoom_changed", () => {
      setZoom(map.getZoom());
    });
    // button to get location
    const locateButton = document.createElement("button");
    const locateIcon = document.createElement("img");
    locateButton.type = "button";
    locateIcon.src =
      "https://img.icons8.com/color-glass/48/google-maps-new.png";
    locateIcon.alt = t("locate");
    locateButton.appendChild(locateIcon);
    locateButton.title = t("addCurrentLocation");
    locateButton.classList.add("locate-button");
    locateButton.addEventListener("click", updateDefaultLocation);
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      locateButton
    );
    // button to focus on the circle
    const toCircle = document.createElement("button");
    const circleIcon = document.createElement("img");
    toCircle.type = "button";
    circleIcon.src = "https://img.icons8.com/color/48/define-location--v1.png";
    circleIcon.alt = t("circle");
    toCircle.appendChild(circleIcon);
    toCircle.title = t("focusToCircle");
    toCircle.classList.add("circle-button");
    toCircle.addEventListener("click", () => focusToCircleRef.current());
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      toCircle
    );
  };

  return (
    <Box my={1}>
      <TextField
        id="range"
        label={t("interestRange")}
        name="range"
        variant="outlined"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        InputLabelProps={{ shrink: !!interestRange }}
        sx={{ width: "100%" }}
        value={interestRange}
        onChange={(e) => setInterestRange(e.target.value)}
      />
      <Box>
        <Slider
          aria-label="Small steps"
          value={interestRange}
          onChange={(event) => setInterestRange(event.target.value)}
          step={1}
          marks={marks}
          min={0}
          sx={{
            mt: 0.5,
          }}
          max={maxRange}
          valueLabelDisplay="auto"
        />
      </Box>
      {center?.lat && center?.lng ? (
        <GoogleMap
          onLoad={handleMapLoad}
          mapContainerClassName="map-container"
          center={center}
          zoom={zoom}
        >
          {location?.lat && location?.lng && (
            <>
              <RangeCircle
                interestRange={interestRange}
                setInterestRange={setInterestRange}
                location={location}
                setLocation={setLocation}
              />
              {userLocation?.lat && (
                <MarkerF
                  position={userLocation}
                  icon={{
                    url: "location-pin.png",
                    scaledSize: new window.google.maps.Size(35, 35),
                  }}
                />
              )}
            </>
          )}
        </GoogleMap>
      ) : (
        <Loader />
      )}
    </Box>
  );
}

export default LocationAndRange;
