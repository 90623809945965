import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { searchUser, toggleModal } from "../../redux/searchUserSlice";
import Utils from "../../utils/Utils";
import PostDirectory from "../PostDirectory";
import UserAvatar from "../UserAvatar";
const { getUserFeedLink } = Utils;

export default function UsersResultModal({ filter }) {
  const { t } = useTranslation(["user", "common"]);
  const dispatch = useDispatch();
  const { isModalShown, users, hasMore, totalMatches, status } = useSelector(
    (state) => state.searchUser
  );
  const handleClose = () => {
    dispatch(toggleModal());
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sentinelRef = useRef();
  const scrollRef = useRef();

  const [lastLength, setLastLength] = useState(0);

  useEffect(() => {
    let timeout;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore && status !== "loading") {
          timeout = setTimeout(() => {
            if (users.length > lastLength) {
              setLastLength(users.length);
              dispatch(searchUser(filter));
            }
          }, 200); // Debounce time
        }
      },
      { root: scrollRef.current, threshold: 1.0 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      clearTimeout(timeout);
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [dispatch, filter, hasMore, status, users.length, lastLength]);
  return (
    <Modal
      aria-labelledby="Users list"
      aria-describedby="Users list"
      open={isModalShown}
      onClose={handleClose}
      closeAfterTransition
      disableScrollLock
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 250,
        },
      }}
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem 0",
      }}
    >
      <Zoom in={isModalShown}>
        <Paper
          elevation={2}
          sx={{
            display: "block",
            zIndex: 1,
            top: 0,
            maxWidth: "600px",
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
            margin: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">
              {t("matchedUsers")}{" "}
              <span className="text-lg">
                ({users.length}/{totalMatches})
              </span>
            </Typography>
            <IconButton onClick={handleClose} sx={{ ml: "auto" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            ref={scrollRef}
            key="scroll-box"
            sx={{
              maxHeight: "75dvh",
              overflowY: "auto",
              paddingRight: 0.5,
            }}
          >
            {users.map((user) => (
              <Accordion
                expanded={expanded === user._id}
                onChange={handleChange(user._id)}
                sx={{
                  borderRadius: 3,
                  my: 0.5,
                  boxShadow: "none",
                  "&:before": { height: 0 },
                }}
                key={user._id + user.username}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${user._id}-content`}
                  id={`${user._id}-header`}
                >
                  <Box sx={{ display: "flex", flexFlow: "row nowrap", gap: 1 }}>
                    <Box sx={{ width: "75px", height: "75px" }}>
                      <UserAvatar
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: 0,
                          "& > div": {
                            height: "100%",
                            width: "100%",
                          },
                          "& img": {
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            mb: 0,
                          },
                        }}
                        userId={user._id}
                        user={user}
                        username={user.username}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {user?.username}
                      </Typography>
                      <Typography>
                        <Typography
                          component="span"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {t(`${user?.gender?.toLowerCase() || ""}`)}
                        </Typography>
                        <Typography component="span" sx={{ mx: 1 }}>
                          &#183;
                        </Typography>
                        <Typography component="span">
                          {new Date().getFullYear() - user.yearOfBirth || ""}{" "}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    component="span"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: -0.5,
                      mb: 2,
                    }}
                  >
                    <PlaceIcon />
                    {user?.city}
                  </Typography>
                  <Box className="flex gap-1 flex-wrap">
                    {user.labels &&
                      Object.keys(user.labels).map((category) =>
                        user.labels[category].map((item) => (
                          <Chip
                            key={item}
                            label={
                              user.translatedLabels.translations[category][item]
                            }
                            variant="filled"
                          />
                        ))
                      )}
                  </Box>
                  {user?.subscribedInterests?.length === 0 && (
                    <Typography>{t("common:noSubscriptions")}</Typography>
                  )}
                  <List
                    sx={{
                      "& .MuiListItem-root": {
                        p: 0,
                        px: 1,
                      },
                    }}
                  >
                    {user?.subscribedInterests?.map((item) => (
                      <ListItem key={item._id} className="[&_a]:text-blue-600">
                        <NavLink
                          onClick={() => handleClose()}
                          to={getUserFeedLink(item.path, user._id)}
                        >
                          <PostDirectory
                            sx={{
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                            post={item}
                          />
                        </NavLink>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
            {status !== "loading" && (
              <div
                ref={sentinelRef}
                style={{ height: 1, visibility: "hidden" }}
              />
            )}
            {status !== "loading" && users.length === 0 && (
              <Typography sx={{ textAlign: "center" }}>
                {t("noMembersFound")}
              </Typography>
            )}
          </Box>
        </Paper>
      </Zoom>
    </Modal>
  );
}
